.main {
    background-color: #F9F9F9;
}

.col-gap {
    column-gap: 2rem;
}

.card-formation {
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
}

.modal-container {
    width: 50% !important;
    height: 50% !important;
    background-color: white;
}

.dialog-container {
    padding: 15% !important;
}

.head-title {
    font-family: WorkSans, sans-serif;
    color: #707070;
    font-size: 16px;
    width: 60%;
}

.video-card-container {
    display: flex;
    justify-content: space-between;
    border: 1px solid lightgrey;
    margin-top: 3%;
    border-radius: 10px;
    padding: 2%;
    cursor: pointer;
    background-color: white;
}

.title-container {
    width: 60%;
}

.min {
    width: 14%;
    text-align: center;
}

.format {
    background-color: #F9D3A4;
    align-items: center;
    padding-right: 3%;
    padding-left: 3%;
    height: 50%;
    width: 14%;
    border-radius: 8px;
}

.format p {
    color: #FF9C46;
    opacity: 100%;
    font-weight: bold;
    text-align: center;
}

.title-container h5 {
    font-size: 16px;
}

.title-container p {
    font-family: WorkSans, sans-serif;
    font-size: 15px;
    color: #A0A0A0;
}

.title-document-container {
    display: flex;
}

.title-document-container h1 {
    width: 63vw;
}

.title-document-container h5 {
    width: 14%;
}

.close-icon {
    width: 20px;
    margin-left: 98%;
    cursor: pointer;
    margin-bottom: 5%;
}

.dialog-inner-container {
    padding: 3%;
}

.suivant-button p {
    color: white;
}

.faceId {
    background-color: #47A1FF;
}

.enter-email {
    width: 50%;
    height: 100vh;
    padding: 5%;
}

.faceId {
    width: 50%;
}

.face-id-container {
    width: 15%;
    margin: 0 auto;
}

.face-id-container img {
    width: 100%;
}

.id-title {
    color: white;
    margin-top: 5%;
    text-align: center;
}

.shape1 {
    margin-left: -5%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.shape2 {
    margin-left: 60%;
    margin-top: 10%;
}

.connection-text {
    margin-top: 10%;
    margin-bottom: 8%;
}

.suivant-button {
    width: 20%;
    cursor: pointer;
    border-radius: 10px;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    background: rgb(71,161,255);
    background: linear-gradient(90deg, rgba(71,161,255,1) 0%, rgba(46,128,213,1) 39%, rgba(22,97,174,1) 66%, rgba(0,68,139,1) 100%);
    margin-top: 2%;
    margin-left: 80%;
}

.forbidden-button {
    width: 20%;
    cursor: pointer;
    border-radius: 10px;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    background: rgb(71,161,255);
    background: linear-gradient(90deg, rgba(71,161,255,1) 0%, rgba(46,128,213,1) 39%, rgba(22,97,174,1) 66%, rgba(0,68,139,1) 100%);
    margin: 0 auto;
}

.forbidden-button p {
    text-align: center;
    color: white;
    margin-bottom: 0;
}
.suivant-button p {
    margin-bottom: 0;
}

.suivant-button p {
    text-align: center;
}

.input-container input {
    border: none;
    width: 80%;
    outline: none;
    color: #607D99;
}

.email-icon {
    color: #607D99;
    margin-right: 1%;
    margin-bottom: 2px;
}

.input-container {
    border: 1px solid lightgrey;
    border-radius: 10px;
    padding-left: 5%;
    color: #607D99;
    padding-top: 2%;
    padding-bottom: 2%;
}

.contact-admin {
    border: 1px solid #E74C3C;
    cursor: pointer;
    margin-top: 5%;
    margin-bottom: 5%;
    padding-top: 5%;
    padding-bottom: 1.5%;
    color: #E74C3C;
}

.contact-admin:hover {
    color: white;
    background-color: #DF6E6E;
}

.contact-admin-text:hover{
    color: white;
}

.contact-admin-inner {
    display: flex;
    width: 50%;
    margin: 0 auto;
}

.contact-admin-inner p {
    text-align: center;
}

.contact-admin p {
    text-align: center;
    font-weight: bold;
}

.paper-plane-icon {
    color: #E74C3C;
    margin-right: 3%;
    width: 25px;
}

.dialog-member-container {
    padding: 3%;
}

.restant-container {
    display: flex;
    justify-content: space-between;
}

.card-restant {
    border: 1px solid #D5E3D6;
    padding: 3%;
}

.card-restant-title {
    display: flex;
    justify-content: space-between;
}

.delai-connection {
    color: #365E85;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 23px;
    text-align: center;
}

.duration {
    text-align: center;
    font-weight: bold;
    font-size: 25px;
}

.restant {
    font-weight: bold;
    color: #365E85;
}

.module-title {
    color: #727272;
    text-align: right;
}

.module-title-inner {
    color: #727272;
}
.module-span-inner {
    color: #727272;
    font-size: small;
}

.module-card-member {
    border: 1px solid #D5E3D6;
    padding: 3%;
    cursor: pointer;
}

.img-module-container {
    width: 80px;
    height: 80px;
}

.img-module-container img {
    width: 100%;
}

.temps-restant {
    color: #365E85;
    font-weight: bold;
}

.cross-img {
    height: 25px;
    margin-top: 1%;
    cursor: pointer;
}

.img-background {
    opacity: 0.4;
    background-color: #282c34;
}

.img-lock {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -15px;
    margin-left: -17px;
}

.card-body-hover:hover {
    background-color: #00448B;
    color: white;
}

.card-locked {
    position: relative;
    background-color: #000000b8;
}

.card-body-locked {
    opacity: 0.5;
}

.min-text {
    width: 14%;
    text-align: center;
}

.user-img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
}

.card-home-img {
    height: 250px!important;
}

.popup-img {
    border-radius: 15px;
    height: 100%;
    width: 100%;
}

.temps-restant-container {
    margin-top: 20px;
}

.copyright-forbidden {
    margin-top: 3   0%!important;
}

@media (max-width: 1900px) {
    .title-document-container h1 {
        width: 63vw;
    }
}

@media (max-width: 1300px) {
    .title-document-container h1 {
        width: 62vw;
    }
    .shape2 {
        margin-left: 44%;
    }
}

@media (max-width: 1000px) {
    .title-document-container h1 {
        width: 55vw;
    }
    .title-document-container h5 {
        width: 18%;
    }
    .title-container {
        width: 35%;
    }
    .delai-connection {
        font-size: 20px;
    }
    .restant {
        font-size: 18px;
    }
    .restant-text {
        font-size: 18px;
    }
}

@media (max-width: 700px) {
    .title-document-container h1 {
        width: 60vw;
    }
    .img-member-area-container {
        display: none;
    }
    .restant {
        text-align: center;
    }
    .restant-text {
        text-align: center;
    }
}

