.right-nav {
    align-self: flex-end;
    width: 20%;
    display: flex;
    justify-content: space-between;
}

.user-infos {
    display: inline-flex;
}

.member-para {
    margin-top: 12px ;
}

.member-container-nav {
    margin-bottom: 17px;
}

.big-nav {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F2F2F2;
    padding: 0;
}

.restant-per-module {
    text-align: right;
}

.member-area {
    margin-left: 30%;
    height: 50px;
}

.img-user {
    width: 15px;
    height: 15px;
    margin-right: 5%;
    margin-top: 15px;
}

.user-img-display {
    width: 50px;
    height: 50px;
    border-radius: 20px;
}

.restant-text-container {
    margin-top: 1%;
    margin-left: 5%;
}

.contact-admin-text {
    text-align: center;
    margin-bottom: 0;
    margin-top: -3%;
}

.restant-text {
    font-size: 20px;
}

.img-member-area-container {
    width: 25%;
    margin-bottom: 5%;
}

.chevron-right {
    margin-left: 8px;
    height: 13px;
}
.img-member-area {
    width: 100%
}
.member-area {
    width: 150px;
    margin-top: 1%;
    border-right: 1px solid #F2F2F2;
}

.navbar-left {
    width: 65%;
    cursor: pointer;
}

.img-notifications {
    align-items: center;
}

.name-link {
    width: 50%;
    border-left: 1px solid #F2F2F2;
}

.img-user-connected {
    width: 25px;
    height: 25px;
    margin-right: 5%;
}

@media (max-width: 1400px) {
    .member-area {
        margin-left: 22%;
    }
}

@media (max-width: 1200px) {
    .member-area {
        margin-left: 5%;
    }
}

@media (max-width: 550px) {
    .restant-container {
        flex-direction: column;
    }
    .delai-connection {
        text-align: center;
    }
    .card-restant {
        margin-bottom: 1rem;
    }
    .contact-admin-inner {
        width: 90%;
    }
}
