.users-container {
    background-color: #F4F7F9;
    width: 100%;
}

.table-users {
    margin-top: 3%;
}

.react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important
}

.input-table {
    width: 100%;
}


.main-admin {
    margin-left: 13%;
    width: 87%;
}

.description-module-container {
    width: 40%;
}

.button-bootstrap {
    background-color: #9799a3;
    border-color: #000000;
    color: white;
}

.button-bootstrap:hover {
    background-color: #1f1d3a;
    border-color: #1f1d3a;
    color: white;
}
.header-table-users {
    background-color: #BAF9A4;
}

.user-table-head {
    background-color: #9799a3;
}
.three-dots-icons {
    height: 30px;
    width: 30px!important;
    cursor: pointer;
}

.table-tr {
    position: relative;
}

.import-user-container {
    align-self: center;
    width: 50%;
    margin: 0 auto;
}

@media screen and (max-width: 1900px) {
    .main-admin {
        margin-left: 15%;
        width: 85%;
    }
}
