.pro-sidebar > .pro-sidebar-inner {
  background: #1f1d3a !important;
}

.pro-sidebar-inner {
  background: #1f1d3a !important;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background-color: transparent !important;
}

.pro-sidebar {
  color: white !important;
  position: fixed !important;
  width: 13% !important;
  min-width: 13% !important;
}

.img-sidebar {
  width: 100%;
}

.img-sidebar-container {
  width: 60%;
  margin: 5% auto;
}

.pro-sidebar > .pro-sidebar-inner {
  height: 100vh !important;
}

.navbar-dropdown {
  width: 15% !important;
}

.dropdown-divider {
  width: 100% !important;
}

.qty-container {
  position: relative;
}

.qty-inner-container {
  position: absolute;
  bottom: 65%;
  border-radius: 50%;
  background: #6200ee;
  right: -20%;
  width: 16px;
  height: 16px;
}

.qty-inner-container p {
  color: white;
  text-align: center;
  font-size: 10px;
  font-family: Rubik-Regular, sans-serif;
}

@media screen and (max-width: 2190px) {
  .pro-sidebar {
    width: 15% !important;
    min-width: 15% !important;
  }
  .main-admin {
    width: 100% !important;
    margin-left: 15%;
  }
}

@media screen and (max-width: 1900px) {
  .pro-sidebar {
    width: 17% !important;
    min-width: 17% !important;
  }
  .main-admin {
    width: 83% !important;
    margin-left: 17%;
  }
}
@media screen and (max-width: 1700px) {
  .pro-sidebar {
    width: 19% !important;
    min-width: 19% !important;
  }
  .main-admin {
    width: 81% !important;
    margin-left: 19%;
  }
}
