.img-card-container {
  height: 120px;
}

.img-card-container img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.video-preview-container {
  padding: 5%;
  width: 1000px;
}

.pdf-document .react-pdf__Page {
  width: 50px;
}

.header-document {
  background-color: #f9f9f9;
  border: none;
}
.inner-video-card-container {
  display: flex;
  width: 60%;
}
.description-card-video-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2%;
}

.cross-img-pdf {
  margin-left: 96%;
  margin-bottom: 2%;
}
.format-card {
  margin-right: 0;
}

.format {
  padding-top: 1%;
  padding-bottom: 1%;
}

.format p {
  margin-bottom: 0;
}

.cross-img-card-video {
  margin-top: -5%;
  margin-right: -2%;
  float: right;
}

.rpv-core__page-layer {
  width: 100%;
}

.white {
  color: white;
  margin-bottom: 0;
}
.arrow-right {
  margin-left: 4%;
  margin-top: 4px;
}

.squares-img {
  margin-right: 10px;
}

.debuter-container {
  width: 25em;
}

.see-all-modules-container {
  width: 15em;
  display: flex;
  cursor: pointer;
}

.debuter {
  background-color: black;
  width: 100%;
  display: flex;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 2%;
  padding-right: 2%;
  margin-bottom: 4%;
  justify-content: space-between;
}

.modal-dialog {
  max-width: 1010px;
  margin: 1.75rem auto;
}
.modal-content {
  border-radius: 1rem;
}

@media (max-width: 1200px) {
  .video-preview-container {
    width: 800px;
  }
  .modal-dialog {
    max-width: 810px;
  }
}

@media (max-width: 900px) {
  .video-preview-container {
    width: 600px;
  }
  .modal-dialog {
    max-width: 610px;
  }
}

@media (max-width: 700px) {
  .video-preview-container {
    width: 400px;
  }
  .cross-img-card-video {
    margin-left: 96%;
  }
  .modal-dialog {
    max-width: 410px;
  }
}

@media (max-width: 600px) {
  .debuter {
    display: none;
  }
}
@media (max-width: 550px) {
  .format,
  .min {
    display: none;
  }
  .min-text {
    display: none;
  }
  .video-card-container {
    padding: 5%;
  }
  .video-preview-container {
    width: 300px;
  }
  .modal-dialog {
    max-width: 310px;
  }
  .title-container {
    width: 48%;
  }
  .cross-img {
    width: 15px;
    height: 15px;
  }
}
