.login-container {
    display: flex;
}

.camera-container {
    display: flex;
}

.webcam-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.canvas-webcam {
    position: absolute;
    text-align: center;
    z-index: 8;
    left: 1%;
    display: none;
    top: 1%
}

.copyright {
    margin-top: 60%
}

.face-recongition-image {
    position: absolute;
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}
@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: none!important;
    border-radius: 10px!important;
}

.explication-text {
    font-size: 12px;
    text-align: center;
}

.image-saved-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 2%;
}

.css-uhb5lp {
    max-width: none!important;
}

.preview-img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 2%
}

.email-input {
    color: #607D99;
}

.email-input::placeholder {
    color: #607D99;
}

.error {
    color: white;
    padding: 10px;
    background-color: #f26464;
    border-radius: 10px;
    margin-top: 2em;
}

.warning-icon {
    color: white;
    margin-right: 10px;
}

.validate {
    color: white;
    padding: 10px;
    background-color: #09e01fba;
    border-radius: 10px;
    margin-top: 2em;
}

.validate-blue {
    color: white;
    padding: 10px;
    background-color: #47a1ff;
    border-radius: 10px;
    margin-top: 2em;
}

.validate-login {
    width: 120%;
    margin: 2% auto;
    align-self: center;
    padding-top: 5%;
    padding-bottom: 5%;
}

.validate-user-container {
    position: absolute;
    bottom: 8%;
    left: 22%;
}

.validate-user-container-blue {
    position: absolute;
    bottom: 8%;
    left: 15%;
}

.check-circle {
    margin-right: 2%;
}

@media (max-width: 1000px) {
    .login-container {
        flex-direction: row-reverse;
        height: 100vh;
    }

    .copyright {
        margin-top: 150%
    }
}

@media (max-width: 780px) {
    .login-container {
        flex-direction: column;
    }
    .enter-email, .faceId {
        width: 100%;
        height: 50%;
    }
    .copyright {
        margin-top: 20%
    }
    .shape1, .shape2 {
        display: none;
    }
    .webcam-container {
        width: 100%;
    }
    .preview-img-container {
        width: 100%;
    }
    .camera-container {
        flex-direction: column;
    }
    .face-id-container {
        margin-top: 10%;
    }
    .faceId {
        height: 50%;
    }
}

@media (max-width: 550px) {
    .faceId {
        display: none;
    }
    .copyright {
        margin-top: 80%
    }
    .img-logo {
        width: 100px;
        height: auto;
    }
    .canvas-webcam {
        position: absolute;
        z-index: 8;
        left: -16%;
        top: 0%;
        width: 900px;
        height: 500px;
    }
    .connection-text {
        margin-top: 40%;
    }
    .face-recongition-image {
        width: 175px;
        top: 23px;
    }
    .suivant-button {
        width: 100%;
        margin-left: 0;
    }
    .validate-user-container-blue {
        position: absolute;
        left: 10%
    }
    .validate-login {
        width: 100%;
    }
    .validate-container {
        width: 100%;
        margin-top: 1em;
        margin-bottom: 1em;
    }
    .explication-text {
        font-size: 14px;
    }
}

@media (max-width: 450px) {
    .face-recongition-image {
        width: 145px;
        top: 14px;
    }
    .suivant-button {
        width: 100%;
        margin-left: 0;
    }
    .validate-user-container-blue {
        position: absolute;
        left: 50%;
        bottom: -14%;
        width: 100%;
    }
    .validate-login {
        padding-top: 1%;
        padding-bottom: 1%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .validate-user-container {
        position: absolute;
        bottom: -14%;
        left: 50%;
        width: 100%;
    }
}
