.img-preview {
    width: 200px;
}

.image-saved-container {
    align-items: center;
}

.margin-auto {
    margin: 3% auto;
    width: 200px;
}
