.add-module-form {
    display: flex;
    flex-direction: column;
}

.preview-img {
    height: 300px;
}

.module-span {
    font-weight: bold;
}

.card-module {
    justify-content: space-between;
}
.popup-container {
    padding: 5%;
    align-items: center;
    cursor: pointer;
}

.module-admin-img {
    border-radius: 20px;
}
